export const contactForm = {
	id: 'discover',
	lightBg: true,
	lightText: false,
	lightTextDesc: false,
	topLine: 'Company',
	headline: 'WristRoad',
	description: 'Mobile Car Game',
	buttonLabel: "Download Now!",
	imgStart: false,
	img: require('../../images/icon_converted.png').default,
	alt: 'Planet',
	dark: false,
	primary: false,
	darkText: true,
}